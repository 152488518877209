import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import { Link } from "gatsby"
// import { withPrefix } from 'gatsby'
import useSiteMetadata from '../components/use-site-metadata';
// import Icon from '../components/icons';
// import DOD from '../components/dods';
// import ReactGA from 'react-ga';
// import * as queryString from "query-string";
import ImpactStories from "../components/impact-story-card";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';

import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon
} from 'react-share';

export default function Template({
  data,
  location
}) {

  const { siteUrl } = useSiteMetadata();
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  
  var pageClass = frontmatter.path;
  pageClass = pageClass.split("/").pop();

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title}</title>
        <body className={"impact-story " + pageClass} />

        <link rel="canonical" href={`${siteUrl}${frontmatter.path || "/"}`} />
        <meta name="description" content={frontmatter.desc} />

        {/* Schema.org markup for Google+ */}
        <meta itemprop="name" content={frontmatter.title} />
        <meta itemprop="description" content={frontmatter.desc} />
        <meta itemprop="image" content={frontmatter.socialImage.publicURL} />

        {/* Twitter Card data */}
        <meta name="twitter:title" content={frontmatter.title} />
        <meta name="twitter:description" content={frontmatter.desc} />
        <meta name="twitter:image:src" content={frontmatter.socialImage.publicURL} />

        {/* Open Graph data */}
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:url" content={`${siteUrl}${frontmatter.path || "/"}`} />
        <meta property="og:image" content={frontmatter.socialImage.publicURL} />
        <meta property="og:image:alt" content={frontmatter.title} />
        <meta property="og:description" content={frontmatter.desc} />

        {/* <link rel="stylesheet"  href="https://cdn.jsdelivr.net/gh/CSUF-Strat-Comm/campus-template@latest/_assets/main.css" type="text/css" media="all" /> */}
      </Helmet>
      <div className={`impact-story-container ${pageClass}`}>
        <h1 style={{display: 'none'}}>{frontmatter.title}</h1>
        <section className="hero-banner" style={{backgroundImage: `url(${frontmatter.heroImage.publicURL})`}}>
          <div className="banner-text">
            <p className="heading">{frontmatter.heading}</p>
            <p className="name">{frontmatter.name + ', ' + frontmatter.personTitle }</p>
          </div>
          <div className={`tint`} />
        </section>
        <div className="wrap content">
          <div className="row">
            <div className="col-8" dangerouslySetInnerHTML={{ __html: html }} />
            <div className="col-4">
              <h2 className="text-sans-serif text-size-lg text-weight-600">Share {frontmatter.firstName}'s Story</h2>
              <div className="sharing">
                <FacebookShareButton
                  url={`${siteUrl}${frontmatter.path || "/"}`}>
                  <FacebookIcon
                  aria-label="Facebook"
                  size={38}
                  borderRadius={0}
                  iconFillColor="#084b8a"
                  bgStyle={{
                    fill: 'white'
                  }} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={`${siteUrl}${frontmatter.path || "/"}`}>
                  <TwitterIcon
                  aria-label="Twitter"
                  size={38}
                  borderRadius={0}
                  iconFillColor="#084b8a"
                  bgStyle={{
                    fill: 'white'
                  }} />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={`${siteUrl}${frontmatter.path || "/"}`}>
                  <LinkedinIcon
                  aria-label="Linkedin"
                  size={38}
                  borderRadius={0}
                  iconFillColor="#084b8a"
                  bgStyle={{
                    fill: 'white'
                  }} />
                </LinkedinShareButton>        
                <EmailShareButton
                  url={`${siteUrl}${frontmatter.path || "/"}`}>
                  <EmailIcon
                  aria-label="Email"
                  size={38}
                  borderRadius={0}
                  iconFillColor="#084b8a"
                  bgStyle={{
                    fill: 'white'
                  }} />
                </EmailShareButton>          
              </div>
              <aside>
                <h2>About the {frontmatter.gifttype}</h2>
                <p dangerouslySetInnerHTML={{ __html: frontmatter.giftInfo }} />
                <span className="by-the-numbers">
                  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36">
                    <defs>
                      <linearGradient id="linear-gradient" x1="0.903" y1="0.209" x2="0.131" y2="0.837" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#ff7900"/>
                        <stop offset="1" stopColor="#ff4500"/>
                      </linearGradient>
                    </defs>
                    <g id="Group_58" data-name="Group 58" transform="translate(0 -0.296)">
                      <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="18.5" cy="18" rx="18.5" ry="18" transform="translate(0 0.296)" fill="url(#linear-gradient)"/>
                      <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(2 2.296)" fill="#002f5c" stroke="#fff" strokeWidth="2">
                        <ellipse cx="16.5" cy="16" rx="16.5" ry="16" stroke="none"/>
                        <ellipse cx="16.5" cy="16" rx="15.5" ry="15" fill="none"/>
                      </g>
                      <path id="Icon_open-info" data-name="Icon open-info" d="M6.467,0A2.156,2.156,0,1,0,8.623,2.156,2.162,2.162,0,0,0,6.467,0ZM3.234,5.389A3.229,3.229,0,0,0,0,8.623H2.156a1.078,1.078,0,1,1,2.156,0c0,.6-2.156,3.535-2.156,5.389a3.189,3.189,0,0,0,3.234,3.234,3.229,3.229,0,0,0,3.234-3.234H6.467a1.078,1.078,0,0,1-2.156,0c0-.776,2.156-3.967,2.156-5.389A3.257,3.257,0,0,0,3.234,5.389Z" transform="translate(13.532 9.072)" fill="#fff"/>
                    </g>
                  </svg>
                  {frontmatter.byTheNumbers}
                </span>
              </aside>
              <aside>
                <h2>It takes a Titan like <em>you</em></h2>
                <p>Cal State Fullerton is the catalyst for Titan-sized transformations, inspiring students’ paths, launching careers and improving lives. The university's first-ever comprehensive philanthropic campaign “It Takes a Titan,” is an opportunity for you to <strong>be the difference</strong> in a Titan’s life.</p>
  
                <a href="http://give.fullerton.edu/91610" className="button pill orange"><strong>Give Now</strong></a>
              </aside>
            </div>
          </div>
          {frontmatter.donorQuote ? (
          <div className="row">
            <div className="col-12">
              <blockquote className="donor-quote">
                <p><span className="opening-quote">“</span>{frontmatter.donorQuote}</p>
                <cite>&mdash; {frontmatter.donorName}{frontmatter.donorTitle ? ( <span>{', ' + frontmatter.donorTitle}</span> ) : null}</cite>
              </blockquote>
            </div>
          </div>
          ) : null}
        </div>
        <section id="other-stories">
            <div className="stories-desc text-white text-size-lg">
              <h2 className="hidden">More Stories</h2>
              <p>Students share, in their own words, how they were empowered by their Titan education. Thanks to you, our generous community of donors, these students discovered their passions and leadership potential in academics, arts and athletics.</p>
              <Link to="/impact" className="button pill orange">View More Stories</Link>
            </div>
            <div id="impact-stories">
              <ImpactStories path={frontmatter.path} />
            </div>
        </section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        heroImage {
          publicURL
        }
        inlineImage {
          publicURL
        }
        socialImage {
          publicURL
        }
        path
        title
        desc
        college
        donorQuote
        donorName
        donorTitle
        heading
        name
        firstName
        personTitle
        gifttype
        giftInfo
        byTheNumbers
      }
    }
  }
`